<template>
	<z-plate title="作品上报" style="width: 100%;min-height: 550px;">
		<!-- 表格 -->
		<el-table class="z-table" :data="tableData" v-loading="tableLoading" style="width: 100%">
			<el-table-column type="index" label="序号" width="60" align="center"></el-table-column>

			<el-table-column prop="name" label="活动名称" show-overflow-tooltip align="center"></el-table-column>

			<el-table-column prop="name" label="作品上报时间" width="300" align="center">
				<template slot-scope="{ row }">{{row.uploadStartTime | YMDHm}} ~ {{row.uploadEndTime | YMDHm}}</template>
			</el-table-column>

			<el-table-column label="上报视频作品数量" width="200" align="center">
				<template slot-scope="{ row }">
					<strong class="color-primary">{{row.videoCount}}</strong>
				</template>
			</el-table-column>

			<el-table-column prop="address" label="操作" width="200" fixed="right" align="center">
				<template slot-scope="{ row }">
					<el-button type="primary" @click="turnToAdd('add', row)" v-if="row.show">上报</el-button>

					<el-button @click="turnToAdd('show', row)" v-else>查看</el-button>
				</template>
			</el-table-column>
		</el-table>

		<!-- 分页 -->
		<!--<z-pagination v-if="pagination.total > 0" :page.sync="pagination.page" :limit.sync="pagination.limit" :total="pagination.total" @pagination="init"></z-pagination>-->
	
		<!-- 上传附件提示 -->
		<el-dialog class="z-dialog" title="提示信息" :visible.sync="dialogFormVisible" width="55%" center :close-on-click-modal="false" >
			<div v-html="uploadMessage"></div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitBtn">确 定</el-button>
			</span>
		</el-dialog>
	</z-plate>
</template>

<script>
	export default {
		data() {
			return {
				addDialog: false,
				tableData: [],
				pagination: {
					page: 0, //当前页
					limit: 10, //分页条数
					total: 0 //总页数
				},
				tableLoading: false,
				dialogFormVisible: false,
				uploadMessage: ''
			};
		},

		mounted() {
			this.init();
		},

		methods: {
			init() {
				this.tableLoading = true;

				this.$post("/queryUserActivity", {}).then(m => {
					if(m.resultCode == 0) {
						this.tableData = m.content.list;
						this.tableLoading = false;
					}
				})

			},
			turnToAdd(flag, row) {
				if(flag == 'add'){

					this.$post("/queryActivityInfo", {"activityid": row.id}).then(m => {
						if(m.resultCode == 0) {
							this.dialogFormVisible = true;

							var activity = m.content.activity;
							this.uploadMessage = activity.uploadMessage;
							// this.$alert(activity.uploadMessage, '提示信息', {
							// 	dangerouslyUseHTMLString: true
							// });
						}
					})
					
				}else{
					this.$router.push({name: 'ManagementWorks'});
				}
				sessionStorage.setItem("flag",flag);
				sessionStorage.setItem("activityid",row.id);
			},
			submitBtn(){
				this.dialogFormVisible = false;
				this.$router.push({name: 'ManagementWorks'});
			}
		}
	};
</script>

<style lang="scss" scoped>

</style>